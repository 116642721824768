@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hiddeni{
  opacity: 0;
  filter: blur(5px);
  transition: all 1s;
  /*transform: translateX(100%);*/
  }
.showi{
  opacity: 1;
  filter: blur(0);
  /*transform: translateX(0);*/
  }
  .commentSection{
    top: -350px;
    .textsize{
      font-size:  1.2rem;
      line-height: 170%;
     }
     .textsize2{
      font-size: 0.8rem;
     }
    }
  .moveTop{
    top: -400px;
    position: relative;
  }

  @media only screen and (max-width: 2900px) and (min-width: 1700px){
    
  }
  @media only screen and (max-width: 1700px) and (min-width: 1400px){
  
  }
  @media only screen and (max-width: 1400px) and (min-width: 1100px){
    
  }
  @media only screen and (max-width: 1100px) and (min-width: 700px){
    .commentSection{
      top: -350px;
     
    }
  }
  @media only screen and (max-width: 900px) and (min-width: 700px){
   
  }
  @media only screen and (max-width: 700px) and (min-width: 500px){
    .commentSection{
      top: -320px;
     
      
    }

  }

  @media only screen and (max-width: 290px) and (min-width: 0px){
    .commentSection{
      top: -320px;
   
  }
}

  @media only screen and (max-width: 500px) and (min-width: 290px){

    .commentSection{
      top: -320px;
      .textsize{
        font-size:  0.8rem;
        line-height: 170%;
       }
       .textsize2{
        font-size: 0.8rem;
       }
      }
 
  }

  .frequency-bars {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100px; /* Erhöhte Höhe für deutlichere Animationen */
    margin: 1px 0;
  }
  
  .bars {
    width: 20%; /* Schmälere Balken für einen ausgeprägteren Effekt */
    height: 10%; /* Start-Höhe der Balken */
    background-color: #b10e0e; /* Farbe der Balken */
  }
  
  /* Individuelle Animationen 
.bars:nth-child(1) { animation: pulse1 100s ease-in-out infinite; }
.bars:nth-child(2) { animation: pulse2 200s ease-in-out infinite; }
.bars:nth-child(3) { animation: pulse3 150s ease-in-out infinite; }
.bars:nth-child(4) { animation: pulse4 120s ease-in-out infinite; }
.bars:nth-child(5) { animation: pulse5 60s ease-in-out infinite; }*/

@keyframes pulse1 {
  0%, 100% { height: 60%; }
  50% { height: 20%; }
}

@keyframes pulse2 {
  0%, 100% { height: 30%; }
  50% { height: 90%; }
}

@keyframes pulse3 {
  0%, 100% { height: 50%; }
  50% { height: 100%; }
}

@keyframes pulse4 {
  0%, 100% { height: 20%; }
  50% { height: 80%; }
}

@keyframes pulse5 {
  0%, 100% { height: 40%; }
  50% { height: 60%; }
}
[type=checkbox]:checked, [type=radio]:checked {
  background-color: #b10e0e!important;
}

.frequency-container{
 position: relative;
 top: -100px;
 height: 300px;
}

